html {
  background: #000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

.winloss-table ::-webkit-scrollbar {
  display: block;
  height: 15px;
  width: 1px;
}

.winloss-table ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.winloss-table ::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.winloss-table ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.app {
  max-width: 550px;
  margin: 0 auto;
  min-height: 100vh;
  overflow: auto;
  flex-direction: column;
  background-image: url('../assets/images/general-innerbg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px 15px;
  /* padding-top: 55px; */
}

.desktop-app {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: fit-content;
  flex-direction: column;
  background-image: url('../assets/images/login-bg.png');
  background-size: cover;
  background-color: '#182031';
  /* padding: 30px 40px; */
}

/* force write? */
.chakra-ui-light :root[data-theme=light],
[data-theme][data-theme=light] {
  --chakra-colors-chakra-body-bg: #161f30 !important;
}

.app.body-content {
  padding-top: 15px;
}

.app.bg-clear {
  background-image: url('../assets/images/bg-clear.png');
}

.app.bg-black {
  background: black;
}

.desktop-app.bg-login {
  background-image: url('../assets/images/galaxy.png');
}

.app.bg-mini-program-swc {
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(235, 255, 227, 1) 0%, rgba(8, 205, 52, 1) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.chakra-input.input-container {
  background: linear-gradient(#a64aed,#892ac3);
  border-radius: 0;
  border: none;
  opacity: 1;
}

.chakra-input.input-container::placeholder {
  color: #d0a4eb;
}

.chakra-textarea.input-container {
  background: #192031 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0A0D15;
  border: 2px solid #334060;
  border-radius: 4px;
  opacity: 1;
}

.chakra-button.purple-button {
  background: transparent linear-gradient(#ce7dff 0%,#c765ff 10%, #8826c0 100%);
  box-shadow: 0px 3px 8px #A7B7D06C;
  border-top: 2px solid #9139c8;
  border-bottom: 2px solid #7d22b0;
  padding-block: 1.5rem;
  color: white;
  font-size: medium;
  width: 100%;
}

.chakra-button.dark-purple-button {
  background: #4c1570;
  box-shadow: 0px 3px 8px #A7B7D06C;
  padding-block: 2rem;
  color: white;
  font-size: medium;
  width: 100%;
}

.chakra-button.light-purple-button {
  background: #ba5fee;
  box-shadow: 0px 3px 8px #A7B7D06C;
  padding-block: 2rem;
  color: white;
  font-size: medium;
  width: 100%;
}


.chakra-button.grey-button {
  background: transparent linear-gradient(0deg, #C6D1E2 0%, #A0B1CB 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #A7B7D06C;
  border: 2px solid #BECADD;
  border-radius: 4px;
  width: 100%;
}

.chakra-button.green-button {
  background: transparent linear-gradient(0deg, #32CD32 0%, #32CD32 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #32CD32;
  border: 2px solid #32CD32;
  border-radius: 4px;
  width: 100%;
  color: #fff;
}

.chakra-button.grey-share-button {
  background: transparent linear-gradient(0deg, #C6D1E2 0%, #A0B1CB 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #A7B7D06C;
  border: 2px solid #BECADD;
  border-radius: 20px;
  width: 50%;
}

.chakra-button.purple-share-button {
  background: linear-gradient(#530d81, #611792);
  color: white;
  border: 2px solid #7b2dad;
  border-radius: 20px;
}

.chakra-button.grey-gradient-button {
  background-image: linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%);
  /* box-shadow: inset 0px 3px 6px #000000AD, 0px 3px 6px #000000D6; */
  border: 1px solid #f8c763;
  border-radius: 23px;
  color: white;
  font-weight: 300;
  width: 100%;
  opacity: 1;
}

.chakra-button.amount-button {
  background: #400164;
  border: 2px solid #2C3A47;
  border-radius: 23px;
  color: white;
  opacity: 1;
}

.chakra-button.amount-button.selected {
  border: 2px solid #f8c763;
  color: #f8c763;
}

.chakra-divider.app-divider {
  box-shadow: 0px -2px 0px #192031;
  border: 0.5px solid #2D3C5C;
  opacity: 1;
}

.divider-horizontal {
  border-top: 2px solid #5d0f8b !important;
  border-bottom: 2px solid #862fc3 !important;
}

.divider-vertical {
  border-left: 2px solid #5d0f8b !important;
  border-right: 2px solid #862fc3 !important;
  height: 3.4rem !important;
}

.divider-horizontal-dark {
  border-top: 2px solid #030231 !important;
  border-bottom: 2px solid #70459f !important;
  margin-block: 1rem !important;
  width: 110% !important;
  margin-left: 50% !important;
  transform: translate(-50%) !important;
}

.chakra-divider.app-divider-darker {
  border: 1px solid #0D111A;
  opacity: 1;
}

.chakra-divider.app-divider-lighter {
  border: 1.8px solid #9a46cd;
  opacity: 1;
}

.chakra-divider.app-divider-darker-bar-height {
  border: 1px solid white;
  opacity: 1;
  height: 3.4rem;
}

.chakra-divider.app-divider-full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  border-bottom-color: white;
  border-bottom-width: 1.5px;
}

.chakra-divider.app-divider-full-width-setting {
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px -2px 0px #192031;
  border: 0.5px solid white;
}

.chakra-modal__content-container.css-wl0d9u {
  z-index: 999999;
}

.chakra-modal__content-container.css-1ocl83d {
  z-index: 999999;
}

.chakra-modal__content.app-modal-content {
  background: linear-gradient(#ac4cfe,#7416ab);
  box-shadow: 10px 10px 20px #0000007B;
  border: 1px solid #3A4155;
  border-radius: 11px;
}

.chakra-modal__header.app-modal-header {
  color: #f8c763;
  text-align: center;
}

.chakra-modal__body.app-modal-body {
  color: white;
  text-align: center;
  padding: 10px;
}

.chakra-modal__footer.app-modal-footer {
  padding: 0;
  border: 0.5px solid #2D3C5C;
  margin-top: 5px;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.chakra-modal__content.app-picker-content {
  background: linear-gradient(#a43de5, #8127b6);
  box-shadow: 0px 0px 6px #000000A8;
  border: 2px solid #5D1C83;
  border-radius: 20px;
  height: 75vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.chakra-button.modal-cancel-button {
  border-radius: 0px 0px 0px 12px;
  color: #EB5454;
  height: 3rem;
}

.chakra-button.modal-ok-button {
  background: #20C05C 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 11px 0px;
  color: white;
  height: 3rem;
}

.chakra-button.add-player-button {
  background: #5e158b;
  color: white;
  box-shadow: 0px 5px 10px #A7B7D06C;
  border-radius: 0;
  height: 3rem;
}

.app-general-top-bar {
  position: fixed;
  /* background: #1E283A 0% 0% no-repeat padding-box; */
  height: 55px;
  max-width: 550px;
  width: 100%;
  z-index: 99999;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.app-general-top-bar.with-content {
  height: 115px;
}

.app-bottom-bar {
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: row;
  background: linear-gradient(#a84ff1,#6d15a0);
  height: 4.8rem;
  bottom: 0;
  max-width: 550px;
  width: 100%;
  margin-inline: -4vw;
  z-index: 99999;
  padding-block: 0.5rem;
}

.app-bottom-bar-item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app-top-bar {
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: row;
  top: 0;
  background: linear-gradient(#ac4afd,#7416a9);
  box-shadow: 0px 100px 50px #00000033;
  width: 100%;
  z-index: 99999;
}

.chakra-button.app-top-bar-button {
  background: #182031 0% 0% no-repeat padding-box;
}

.app-countdown-timer {
  border: 1px solid white;
  background: #400164  0% 0% no-repeat padding-box;
  border-radius: 22px;
  padding: 0.3rem 1.5rem;
  width: fit-content;
}

form {
  width: 100%;
}

.profile-picture {
  position: relative;
  top: 0;
  left: 0;
}

.rank-image {
  position: relative;
  top: 0;
  left: 0;
}

.profile-image {
  position: absolute;
  top: 3px;
  left: 2.5px;
}

.badge-image {
  position: absolute;
  bottom: -12px;
  left: 2px;
}

.red-dot {
  position: absolute;
  top: 0;
  right: 0;
  height: 8.5px;
  width: 8.5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.red-dot-2 {
  height: 8.5px;
  width: 8.5px;
  background-color: red;
  border-radius: 50%;
  margin-left: 3px;
}

.app-annoucement-box {
  position: relative;
  background: transparent radial-gradient(closest-side at 50% 0%, #8FABC7 0%, #0F273F 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 2px 2px #000000;
  border: 1px solid #C6D1E2;
  border-radius: 4px;
  opacity: 1;
  width: 100%;
  height: 2rem;
}

.chakra-popover__content.app-popover {
  background: linear-gradient(#ac4afd,#7416a9);
  border: 1px solid #f8c763;
  /* background: #0F273F 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 2px 2px #000000;
  border: 1px solid #C6D1E2;
  color: white; */
}

.chakra-popover__content.app-alert-popover {
  background: #0F273F 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 2px 2px #000000;
  border: 1px solid #C6D1E2;
  color: white;
  text-align: center;
}

.chakra-tabs__tab.app-tabs {
  background: #1E283A 0% 0% no-repeat padding-box;
  border: 1px solid #2C3A47;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  color: #C6D1E2;
  height: 2.7rem;
  text-wrap: nowrap;
}

.chakra-tabs__tab.app-tabs[aria-selected=true] {
  border: 1px solid var(--unnamed-color-ffffff);
  background: transparent linear-gradient(180deg, #C3D2E5 0%, #D2DDEB 51%, #8AA2BD 52%, #385976 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000089;
  border: 1px solid #FFFFFF;
  border-bottom-width: 0;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  color: black;
  height: 2.7rem;
  font-weight: 700;
  text-wrap: nowrap;
}

.app-game-button {
  /* background: transparent linear-gradient(180deg, #171D2B 0%, #28324a 48%, #1d2434 50%, #333D55 100%) 0% 0% no-repeat padding-box; */
  background-image: url('../assets/images/games/home-vendor-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* box-shadow: inset 0px 3px 6px #000000AD, 0px 0px 6px #000000A8; */
  border: 3px solid white;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  width: 100%;
}

.app-game-button.epay {
  display: flex;
  height: 13rem;
}

.chakra-modal__content-container.css-17pwl6t {
  z-index: 99999;
}

.chakra-modal__header.bottom-sheet-header {
  /* background: #232E46 0% 0% no-repeat padding-box; */
  background: linear-gradient(#a74cef, #7415ab);
  border-radius: 20px 20px 0px 0px;
  color: white;
  text-align: center;
  font-size: medium;
}

.chakra-modal__body.bottom-sheet-body {
  background: linear-gradient(#9e35df,#8127b5);
  padding: 0;
  padding-top: 1rem;
}

.player-detail-box {
  width: 100%;
  background: transparent linear-gradient(180deg, #8c32c8 0%, #9b54bc 13%, #9b54bc 15%, #a13be4  50%, #7015a5 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000AD, 0px 0px 6px #000000A8;
  border: 1px solid #3A4155;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.qr-code-container {
  background-color: white;
  justify-content: center;
  align-items: center;
  display: inline-grid;
}

.bottom-sticky-button {
  position: fixed;
  flex: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 550px;
  width: 100%;
}

.chakra-button.profile-setting-button {
  border-bottom: 2px solid #532f83;
  border-radius: 0;
  color: white;
  width: 100%;
  height: 5rem;
}

.experiance-bar {
  background: linear-gradient(#4d117c,#5f1598) !important;
  border: 1px solid #f8c763;
  box-shadow: -1px 0px 0px #3B63D0;
}

.experiance-bar .css-1jrtelv {
  background: linear-gradient(#bda97a, #ba9b3f, #8b681e, #ba9b3f, #ad9b72);
}

.web-view-frame {
  padding-top: 55px;
  position: fixed;
  background: #000;
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.web-view-frame html{
  background-color: red !important;
}

.lucky-spin-web-view-frame {
  padding-top: 55px;
  position: fixed;
  background: #000;
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.account-verification-web-view-frame {
  padding-top: 44px;
  position: fixed;
  background: #000;
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.qr-code-options {
  background: linear-gradient(#3d0067,#54008a);
  color: white;
  padding-inline: 2rem !important;
  border-radius: 15px !important;
  border: 1px solid #671ba5;
  height: 2.2rem !important;
  font-size: small !important;
}

.qr-code-options-selected {
  background: linear-gradient(#a732f0,#5c0091);
  color: white;
  padding-inline: 2rem !important;
  border-radius: 15px !important;
  border: 1px solid #f8c763;
  height: 2.2rem !important;
  font-size: small !important;
}

.my-qr-code-box {
  background: linear-gradient(180deg, #fdeb95 0%, #e5b823 14%, #8d7216 39%, #fdeb95 66%, #e5b823 88%, #fdeb95 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000AD, 0px 0px 6px #000000A8;
  height: 17.5rem;
  width: 17.5rem;
  border-radius: 20px;
  padding-top: 0.75rem;
  margin-top: 1rem;
}

.chakra-button.copy-white-button {
  background: linear-gradient(#bf54fb,#853baf);
  box-shadow: 0px 3px 6px #000000D6;
  border: 1px solid #C6D1E2;
  border-radius: 0px 9px 9px 0px;
}

.chakra-button.my-qr-bottom-button {
  background: linear-gradient(#a94ff1,#9e32dd);
  box-shadow: 0px -2px 10px #00000046;
  border-bottom-width: 0;
  border-radius: 20px 0px 0px 0px;
  height: 3.5rem;
  width: 100%;
  border-radius: 0;
  color: white;
}

.settings-icon-box {
  box-shadow: 0px 0px 6px #000000A8;
  border: 2px solid #3A4155;
  border-radius: 16px;
}

.chakra-card.grey-box {
  width: 100%;
  background: #232E46 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #000000A8;
  border: 2px solid #2D3C5C;
  border-radius: 11px;
}

.chakra-card.purple-box {
  width: 100%;
  background: linear-gradient(#a44eec,#6b159d);
  box-shadow: 0px 0px 6px #000000A8;
  border: 2px solid #f8c763;
  border-radius: 20px;
}

.transaction-box {
  background: linear-gradient(#540083 0%, #7600bb 25%, #67009d 45%, #7600bb 65%, #59008a 100%);
  border: 4px solid #fee284;
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  padding: 1rem;
  margin-bottom: 1rem;
}

.transfer-score-header {
  position: fixed;
  background: linear-gradient(#a640e9,#8b27c7);
  width: 100%;
  padding: 1rem;
  z-index: 99;
  max-width: 550px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -0.5rem;
}

.transfer-score-e-credit-row {
  background: #705563;
  box-shadow: 0px 2px 4px #0000007B;
  margin-inline: -6vw;
  padding-inline: 4vw;
  height: 3rem;
  align-items: center;
  margin-bottom: -1rem;
}

.blink-effect {
  animation: blinker 2.5s linear infinite;
}

.transfer-score-vendor-container {
  margin-top: 13.5rem;
  margin-bottom: 3rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.header-margin-top {
  margin-top: 75px;
}

button.btn-outline {
  border-radius: 100px;
  color: white;
  background: linear-gradient(#5a0089,#7c15bd);
  font-size: 15px;
  padding: 22px 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

button.btn-outline.active,
button.btn-outline:hover {
  border: 1px solid #f8c763;
  background: linear-gradient(#5a0089,#7c15bd);
  color: #f8c763;
  transition: all 1s;
}

.inner-container {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

button.btn-float-btm {
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
  max-width: 300px;
}

.qr-wrapper {
  position: relative;
  margin: -20vw -3.75vw;
}

.qr-scanner-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.scan-area {
  z-index: 1;
  width: 100%;
  height: 100%;
  border: solid rgba(0, 0, 0, .5);
  border-inline-width: 10vw;
  border-block-width: 50vw;
  background:
    linear-gradient(to right, #AACBFF 4px, transparent 4px) 0 0,
    linear-gradient(to right, #AACBFF 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #AACBFF 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #AACBFF 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #AACBFF 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #AACBFF 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #AACBFF 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #AACBFF 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.scan-area.scanned {
  box-shadow: #78ff7d 0px 0px 0px 5px inset !important;
}

.status-tag.SUCCESSFUL,
.status-tag.SUCCESS {
  background: transparent linear-gradient(180deg, #E9FFE3 0%, #5ECC44 15%, #28980E 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #8DE578;
  text-align: center;
  border-radius: 20px;
  padding: 0.25rem;
  color: white;
  opacity: 1;
}

.status-tag.FAILED {
  background: transparent linear-gradient(180deg, #FFBABA 0%, #980D0D 4%, #BA1919 14%, #BE1F1F 49%, #DC4E4E 86%, #FF7B7B 91%, #F97B7B 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #5D491B80;
  border: 1px solid #FF4040;
  text-align: center;
  border-radius: 20px;
  padding: 0.25rem;
  color: white;
  opacity: 1;
}

.status-tag.PENDING {
  background: transparent linear-gradient(180deg, #6F99E0 0%, #B8CBF1 84%, #E2ECFF 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #F5F8FD;
  color: #232E46;
  text-align: center;
  border-radius: 20px;
  padding: 0.25rem;
  opacity: 1;
}

.status-tag.PENDING_APPROVAL {
  background: transparent linear-gradient(180deg, #6F99E0 0%, #B8CBF1 84%, #E2ECFF 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #F5F8FD;
  color: #232E46;
  text-align: center;
  border-radius: 20px;
  font-size: small;
  padding: 0.25rem;
  opacity: 1;
}

.status-tag.APPROVED {
  background: transparent linear-gradient(180deg, #E9FFE3 0%, #5ECC44 15%, #28980E 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #8DE578;
  text-align: center;
  border-radius: 20px;
  color: white;
  padding: 0.25rem;
  opacity: 1;
}

.status-tag.REJECTED {
  background: transparent linear-gradient(180deg, #FFBABA 0%, #980D0D 4%, #BA1919 14%, #BE1F1F 49%, #DC4E4E 86%, #FF7B7B 91%, #F97B7B 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #5D491B80;
  border: 1px solid #FF4040;
  min-width: 10vw;
  text-align: center;
  border-radius: 20px;
  color: white;
  padding: 0.25rem;
  opacity: 1;
}

#payment_iframe {
  width: 100vw;
  height: 110vh;
  background: white;
  margin-left: -15px;
}

span.newBadge {
  background: #cb9929;
  border: 2px solid #ffcc40;
  padding: 0px 4px;
  border-radius: 5px;
  position: absolute;
  top: -5px;
  right: 5px;
  color: white;
  font-size: 10px;
}

img.duitNowImg {
  width: 45px;
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 10px;
  display: block;
}

.eWalletOptionBtn {
  height: fit-content !important;
}

.eWalletRadioBtn {
  width: 15px;
  height: 15px;
  background: rgba(44, 58, 71, 0.3);
  display: block;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.eWalletRadioBtn.active {
  background: linear-gradient(#e5b823, #8d7216);
}

.eWalletOption img {
  width: 55px;
  margin-bottom: 5px;
}

.eWalletRadioContent {
  width: 75px;
}

.eWalletOption p {
  color: white;
  font-size: 11px;
  margin-top: 5px;
}

.eWalletIFrame {
  width: 100vw;
  height: 100vh;
  margin-left: -15px;
}

.chakra-tabs__tab.service-tabs {
  background: linear-gradient(#4f017b,#5a1689,#3e015e);
  border: 1px solid #2C3A47;
  border-bottom-width: 0;
  border-radius: 0;
  color: #d4a5f1;
  height: 3rem;
}

.chakra-tabs__tab.service-tabs[aria-selected=true] {
  border: 1px solid var(--unnamed-color-ffffff);
  background: linear-gradient(#aa4df3,#6228be);
  box-shadow: 0px 3px 6px #00000089;
  border-bottom-width: 0;
  border-radius: 0;
  opacity: 1;
  color: #f8c763;
  height: 3rem;
  font-weight: 700;
}

.chakra-accordion__item.reload-success-accordion {
  background: transparent linear-gradient(#a44eec,#6b159d);
  box-shadow: inset 0px 3px 6px #000000AD, 0px 0px 6px #000000A8;
  border: 2px solid #f8c763;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  color: #f8c763;
}

.css-17mg6aq {
  border: 0;
}

.chakra-button.date-filter-reset-button {
  background: #600296;
  box-shadow: 0px -2px 10px #00000046;
  border: 2px solid #50017d;
  border-radius: 0;
  width: 50vw;
  color: white;
}

.chakra-button.date-filter-apply-button {
  background: transparent linear-gradient(#e5b823, #8d7216);
  box-shadow: 0px -2px 10px #00000045;
  /* border: 2px solid #f8c763; */
  border-radius: 0;
  width: 50vw;
  color: white;
}

.order-box-bg {
  background-image: url('../pages/auth-pages/mini-program/swc/assets/images/green-place-bet.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 15px;
}

.result-box-bg {
  background-image: url('../pages/auth-pages/mini-program/swc/assets/images/green-check-result.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 15px;
}

.react-date-picker__wrapper.result-date-picker {
  border: 0 !important;
}

.tab-container button {
  color: white;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(0deg, #1E283A, #1E283A);
  flex: 1;
  text-wrap: wrap;
  opacity: 0.5;
  border-radius: 0;
  height: 50px;
}

.tab-container button.active {
  color: #f8c763;
  background: linear-gradient(180deg, #aa55f3, #a42de9, #8d19d5);
  opacity: 1;
}

.transaction-item {
  gap: 0;
}

.transaction-item button {
  padding: 8px 0px;
}

.transaction-item-desktop button {
  padding: 12px 0px;
}

img.transaction-icon {
  max-width: 25px;
  width: 100%;
}

.convert-item {
  min-width: 70px;
}

.convert-detail {
  margin-left: -20px;
  margin-right: -20px;
  padding: 5px 20px;
  border: 1px solid #fff;
}

p.chakra-text.convert-amount-input {
  min-width: 150px;
  width: 100%;
  background: linear-gradient(#400164,#9223d4);
  text-align: center;
  border: 1px solid #C6D1E2;
  font-size: 20px;
  padding: 3px;
  margin-top: 2px;
}

p.chakra-text.convert-amount-input.left {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

p.chakra-text.convert-amount-input.right {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.progress-bar {
  height: 2px;
  width: 100vw;
  background: rgba(255, 255, 255, 0.2);
  margin-left: -15px;
  margin-right: -15px;
}

.chakra-button.landing-page-category-button {
  background: linear-gradient(#bc73f9, #7405b4);
  border: 1px solid #A1ACC6;
  border-radius: 20px;
  min-width: unset;
}

.chakra-button.landing-page-category-button-selected {
  background: linear-gradient(#b78215, #fcdf81);
  border-radius: 20px;
  min-width: unset;
}

.landing-page-game-box {
  background: transparent linear-gradient(180deg, #171D2B 0%, #3D4B6E 48%, #22293C 50%, #333D55 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000AD, 0px 0px 6px #000000A8;
  border: 1px solid #C6D1E2;
  border-radius: 5px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.landing-btn {
  animation: zoom-in-zoom-out 1s ease infinite;
  background-image: linear-gradient(180deg, #FFE1B1 0%, #6A4D0D 17%, #FFDD80 33%, #937C32 44%, #EDCC34 62%, #746824 74%, #FFDE6F 86%, #836F00 100%);
  border-radius: 20px;
  padding: 4px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.neon-wrapper {
  position: relative;
  margin: 10px;
  background-color: transparent;
}

.neon-button-1::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  background-color: #000;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  z-index: -1;
  -webkit-animation-name: yellow-shadow;
  animation-name: yellow-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.neon-button-1::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  background-color: #000;
  -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  z-index: -1;
  -webkit-animation-name: cyan-shadow;
  animation-name: cyan-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.neon-button-2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  z-index: -1;
  -webkit-animation-name: gradient-shadow;
  animation-name: gradient-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@-webkit-keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }

  25% {
    top: 50%;
    left: 0;
  }

  50% {
    top: 50%;
    left: 50%;
  }

  75% {
    top: 0;
    left: 50%;
  }

  100% {
    top: 0;
    left: 0;
  }
}

@keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }

  25% {
    top: 50%;
    left: 0;
  }

  50% {
    top: 50%;
    left: 50%;
  }

  75% {
    top: 0;
    left: 50%;
  }

  100% {
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }

  25% {
    right: 0;
    bottom: 50%;
  }

  50% {
    right: 50%;
    bottom: 50%;
  }

  75% {
    right: 50%;
    bottom: 0;
  }

  100% {
    right: 0;
    bottom: 0;
  }
}

@keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }

  25% {
    right: 0;
    bottom: 50%;
  }

  50% {
    right: 50%;
    bottom: 50%;
  }

  75% {
    right: 50%;
    bottom: 0;
  }

  100% {
    right: 0;
    bottom: 0;
  }
}

@-webkit-keyframes gradient-shadow {
  0% {
    -webkit-box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
    box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
  }

  20% {
    -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  }

  40% {
    -webkit-box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
    box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
  }

  60% {
    -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  }

  80% {
    -webkit-box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
    box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
  }

  100% {
    -webkit-box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
    box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
  }
}

@keyframes gradient-shadow {
  0% {
    -webkit-box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
    box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
  }

  20% {
    -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  }

  40% {
    -webkit-box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
    box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
  }

  60% {
    -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  }

  80% {
    -webkit-box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
    box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
  }

  100% {
    -webkit-box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
    box-shadow: 0 0 17px 3px #C586C0, 0 0 4px 2px #C586C0;
  }
}

@-webkit-keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }

  16.66% {
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
  }

  32.32% {
    top: 0;
    left: 50%;
    height: 50%;
    width: 50%;
  }

  49.98% {
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
  }

  66.64% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 100%;
  }

  83.3% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 50%;
  }

  100% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
}

@keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }

  16.66% {
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
  }

  32.32% {
    top: 0;
    left: 50%;
    height: 50%;
    width: 50%;
  }

  49.98% {
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
  }

  66.64% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 100%;
  }

  83.3% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 50%;
  }

  100% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
}

@-webkit-keyframes half-cyan-shadow {
  0% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }

  16.66% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
  }

  32.32% {
    bottom: 0;
    right: 50%;
    height: 50%;
    width: 50%;
  }

  49.98% {
    bottom: 50%;
    right: 50%;
    height: 50%;
    width: 50%;
  }

  66.64% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 100%;
  }

  83.3% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 50%;
  }

  100% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
}

@keyframes half-cyan-shadow {
  0% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }

  16.66% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
  }

  32.32% {
    bottom: 0;
    right: 50%;
    height: 50%;
    width: 50%;
  }

  49.98% {
    bottom: 50%;
    right: 50%;
    height: 50%;
    width: 50%;
  }

  66.64% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 100%;
  }

  83.3% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 50%;
  }

  100% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 363px) {
  .transfer-score-vendor-container {
    margin-top: 14.5rem;
    margin-bottom: 3rem;
  }
}

@media (orientation: landscape) {
  .app {
    max-width: unset;
  }

  .app-general-top-bar {
    max-width: unset;
  }

  .app-bottom-bar {
    width: 110vw;
    max-width: unset;
  }

  .stack-container {
    margin: auto;
    width: 100%;
  }

  .body-content {
    margin: auto;
    width: 100%;
    padding-bottom: 2vh;
  }

  .chakra-slide.chakra-modal__content {
    margin: auto;
  }

  .bottom-sticky-button {
    max-width: unset;
  }

  .chakra-modal__content.app-picker-content {
    height: 50vw;
  }

  .transfer-score-header {
    max-width: unset;
  }

  .transfer-score-e-credit-row {
    margin-inline: -3vw;
  }

  .transfer-score-vendor-container {
    margin-top: 13.5rem;
    margin-bottom: 3rem;
  }

  .badge-image {
    bottom: -7px;
  }

  .inner-container {
    max-width: unset;
  }

  button.btn-float-btm {
    max-width: 50vw;
  }

  .chakra-card.grey-box {
    width: 50%;
  }

  .display-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chakra-button.profile-setting-button {
    height: 5vw;
  }
}

.overlay-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
}

.notification-dot {
  position: absolute;
  top: 0px;
  right: 2px;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block; 
  animation: blinks 0.8s infinite alternate; 
  text-align: center;
  line-height: 20px; 
  font-weight: bold;
  color: white;
  z-index: 2;
}

@keyframes blinks {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.glowing-text {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 5px #FFD055, 0 0 10px #FFD055, 0 0 20px #FFD055, 
               0 0 40px #FFD055, 0 0 80px #FFD055;
  animation: glow .5s infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #FFD055, 0 0 10px #FFD055, 0 0 20px #FFD055, 
                 0 0 40px #FFD055, 0 0 80px #FFD055;
  }
  100% {
    text-shadow: 0 0 10px #CEA029, 0 0 20px #CEA029, 0 0 30px #CEA029, 
                 0 0 50px #CEA029, 0 0 100px #CEA029;
  }
}