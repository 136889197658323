.keyboard {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 550px;
    height: 27vh;
    background: #D0D3DD;
    transition: 0.5s all cubic-bezier(0, 0, 0, 1);
    transform: translate(-50%, 100%);
    z-index: 3;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    padding: 0.3rem;
  }
  .keyboard.keyboard-shown {
    transform: translate(-50%, 0);
    border-radius: 6px 6px 0 0;
  }
  .keyboard .hg-row {
    height: 100%;
    display: grid;
    overflow-y: hidden;
  }
  .keyboard .hg-row .hg-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem;
    border-radius: 12px;
    background: white;
    color: #54595F;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 6px;
    min-height: 2.45rem;
  }
  .keyboard .hg-row .hg-button[data-skbtn="{prev}"] {
    color: #08CD34;
    font-weight: bold;
  }
  .keyboard .hg-row .hg-button[data-skbtn="{next}"] {
    color: #08CD34;
    font-weight: bold;
  }
  .keyboard .hg-row .hg-button[data-skbtn="{add}"] {
    color: #08CD34;
    font-weight: bold;
  }
  .keyboard .hg-row .hg-button[data-skbtn="{lucky}"] {
    color: #08CD34;
    font-weight: bold;
  }
  .keyboard .hg-row .hg-button:active {
    box-shadow: none;
    outline: none;
  }
  .keyboard .hg-row .hg-button img {
    width: 20px;
    height: 20px;
  }
  .keyboard.keyboard-bet .hg-row {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    overflow-y: hidden;
  }
  .keyboard.keyboard-bet .hg-row .hg-button:nth-child(4) {
    font-size: 90%;
    text-align: center;
  }
  .keyboard.keyboard-bet .hg-row .hg-button[data-skbtn="{add}"] {
    grid-area: 0.25/span 0.5;
  }
  .keyboard.keyboard-bet .hg-row .hg-button:nth-child(14) {
    grid-area: 0.75/span 0.5;
    text-align: center;
  }
